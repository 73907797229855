import React, { useState } from "react";
import Header from "../Pages/Header";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Style/Promotion.css";



export default function Promotion() {
  const [linkLogin, setLinkLogin] = useState(
    "https://game.sbobet55.bet"
  );

  const [linkRegister, setLinkRegister] = useState(
    "https://game.sbobet55.bet/register"
  );

  return (
    <>
      <div>
        <Header />
        <div className="promotion">
          <div className="promotion-section">
            <div className="promotion--PC">
              <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Promotion/PcPromotiontext.png"} className="Pc--Promotion--text" />
              <div className="table--games--PC">
                <Row>
                  <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Promotion/PcBgmini.png"} className="bg--tab--blue" />
                  <Tabs defaultActiveKey="tab-1">
                    <Tab eventKey="tab-1" title="ทั้งหมด">
                      <Row>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport1.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport2.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport3.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport4.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport5.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport6.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport7.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport8.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport9.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport10.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport11.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport12.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport13.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport14.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/carsino/carsino1.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/carsino/carsino2.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/carsino/carsino3.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/carsino/carsino4.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/carsino/carsino5.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/slot/slot1.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/slot/slot2.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/slot/slot3.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/slot/slot4.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/slot/slot5.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/lotto/lotto1.png"} className="w-100" />
                          </a>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab eventKey="tab-2" title="กีฬา">
                      <Row>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport1.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport2.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport3.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport4.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport5.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport6.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport7.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport8.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport9.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport10.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport11.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport12.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport13.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport14.png"} className="w-100" />
                          </a>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab eventKey="tab-3" title="คาสิโน">
                      <Row>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/carsino/carsino1.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/carsino/carsino2.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/carsino/carsino3.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/carsino/carsino4.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/carsino/carsino5.png"} className="w-100" />
                          </a>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab eventKey="tab-4" title="สล๊อต">
                      <Row>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/slot/slot1.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/slot/slot2.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/slot/slot3.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/slot/slot4.png"} className="w-100" />
                          </a>
                        </Col>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/slot/slot5.png"} className="w-100" />
                          </a>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab eventKey="tab-5" title="หวย">
                      <Row>
                        <Col md={6} xs={6} className="games PC-2">
                          <a href={linkLogin}>
                            <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/lotto/lotto1.png"} className="w-100" />
                          </a>
                        </Col>
                      </Row>
                    </Tab>
                  </Tabs>
                </Row>
              </div>
            </div>

            <div className="promotion--MB">
              <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/ImgMobie/ImgPormotion/MBTextGroup.png"} className="MB--Text--Group" />
              <div className="table--games--MB">
                <Container>
                  <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Promotion/PcBgmini.png"} className="bg--tab--blueMB" />
                  <Row>
                    <Tabs defaultActiveKey="tab-1">
                      <Tab eventKey="tab-1" title="ทั้งหมด">
                        <Row>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport1.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport2.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport3.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport4.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport5.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport6.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport7.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport8.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport9.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport10.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport11.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport12.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport13.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport14.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/carsino/carsino1.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/carsino/carsino2.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/carsino/carsino3.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/carsino/carsino4.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/carsino/carsino5.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/slot/slot1.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/slot/slot2.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/slot/slot3.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/slot/slot4.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/slot/slot5.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/lotto/lotto1.png"} className="w-100" />
                            </a>
                          </Col>
                        </Row>
                      </Tab>

                      <Tab eventKey="tab-2" title="กีฬา">
                        <Row>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport1.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport2.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport3.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport4.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport5.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport6.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport7.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport8.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport9.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport10.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport11.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport12.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport13.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/sport/sport14.png"} className="w-100" />
                            </a>
                          </Col>
                        </Row>
                      </Tab>

                      <Tab eventKey="tab-3" title="คาสิโน">
                        <Row>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/carsino/carsino1.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/carsino/carsino2.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/carsino/carsino3.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/carsino/carsino4.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/carsino/carsino5.png"} className="w-100" />
                            </a>
                          </Col>
                        </Row>
                      </Tab>

                      <Tab eventKey="tab-4" title="สล๊อต">
                        <Row>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/slot/slot1.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/slot/slot2.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/slot/slot3.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/slot/slot4.png"} className="w-100" />
                            </a>
                          </Col>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/slot/slot5.png"} className="w-100" />
                            </a>
                          </Col>
                        </Row>
                      </Tab>

                      <Tab eventKey="tab-5" title="หวย">
                        <Row>
                          <Col md={12} className="games mb-2">
                            <a href={linkLogin}>
                              <img src={"https://fs.cdnxn.com/landingSbo55/Images/imgpromotion/lotto/lotto1.png"} className="w-100" />
                            </a>
                          </Col>
                        </Row>
                      </Tab>
                    </Tabs>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
