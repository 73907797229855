import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../Pages/Header";
import "../Style/Header.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";


import { Container } from "react-bootstrap";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 480 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 480, min: 0 },
    items: 3,
  },
};

export default function Home() {
  const [linkLogin, setLinkLogin] = useState(
    "https://game.sbobet55.bet"
  );

  const [linkRegister, setLinkRegister] = useState(
    "https://game.sbobet55.bet/register"
  );
  return (
    <>
      <div>
        <Header />
        <div className="home">
          <div className="home-section">
            <div className="home--PC">
              <div className="section--bg--center">
                <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Group2/PcBanner.png"} className="Pc--Banner" />
                <div className="img--bg--pc">
                  <Splide
                    className="slide"
                    options={{
                      rewind: false,
                      gap: "10px",
                      type: "loop",
                      autoplay: true,
                      arrows: false,
                    }}
                  >
                    <SplideSlide>
                      <a href={linkLogin}>
                        <img src={"https://fs.cdnxn.com/landingSbo55/Images/promoPC/promoPC1.jpg"} className="w-100" />
                      </a>
                    </SplideSlide>
                    <SplideSlide>
                      <a href={linkLogin}>
                        <img src={"https://fs.cdnxn.com/landingSbo55/Images/promoPC/promoPC2.jpg"} className="w-100" />
                      </a>
                    </SplideSlide>
                    <SplideSlide>
                      <a href={linkLogin}>
                        <img src={"https://fs.cdnxn.com/landingSbo55/Images/promoPC/promoPC3.jpg"} className="w-100" />
                      </a>
                    </SplideSlide>
                    <SplideSlide>
                      <a href={linkLogin}>
                        <img src={"https://fs.cdnxn.com/landingSbo55/Images/promoPC/promoPC4.jpg"} className="w-100" />
                      </a>
                    </SplideSlide>
                  </Splide>
                </div>
                <a href="https://line.me/ti/p/@sbo55.com">
                  <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Group2/PcLine.png"} className="Pc--Line" />
                </a>
                <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Group2/PcTextAll.png"} className="Pc--Text--All" />
                <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Group2/PcBgBannerIcon.png"} className="Pc--BgBanner--Icon" />
                <div className="img--games--show">
                  <Carousel responsive={responsive}>
                    <div>
                      <a href={linkLogin}>
                        <img src={"https://fs.cdnxn.com/landingSbo55/Images/Game1.png"} className="games--manuPC" />
                      </a>
                    </div>
                    <div>
                      <a href={linkLogin}>
                        <img src={"https://fs.cdnxn.com/landingSbo55/Images/Game2.png"} className="games--manuPC" />
                      </a>
                    </div>
                    <div>
                      <a href={linkLogin}>
                        <img src={"https://fs.cdnxn.com/landingSbo55/Images/Game3.png"} className="games--manuPC" />
                      </a>
                    </div>
                    <div>
                      <a href={linkLogin}>
                        <img src={"https://fs.cdnxn.com/landingSbo55/Images/Game4.png"} className="games--manuPC" />
                      </a>
                    </div>
                    <div>
                      <a href={linkLogin}>
                        <img src={"https://fs.cdnxn.com/landingSbo55/Images/Game5.png"} className="games--manuPC" />
                      </a>
                    </div>
                    <div>
                      <a href={linkLogin}>
                        <img src={"https://fs.cdnxn.com/landingSbo55/Images/Game6.png"} className="games--manuPC" />
                      </a>
                    </div>
                    <div>
                      <a href={linkLogin}>
                        <img src={"https://fs.cdnxn.com/landingSbo55/Images/Game7.png"} className="games--manuPC" />
                      </a>
                    </div>
                  </Carousel>
                  ;
                </div>
              </div>
              <div className="section--bg--foolter">
                <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Group3/PcTextGroup.png"} className="Pc--Text--Group" />
                <div className="section--bg--fooltericon">
                  <div className="table--type1">
                    <a href={linkLogin}>
                      <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Group3/PcAllGame.png"} className="PcAll--Game" />
                    </a>
                    <a href={linkLogin}>
                      <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Group3/PcSport.png"} className="Pc--Sport" />
                    </a>
                    <a href={linkLogin}>
                      <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Group3/PcSlot.png"} className="Pc--Slot" />
                    </a>
                  </div>
                  <div className="table--type2">
                    <a href={linkLogin}>
                      <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Group3/PcCasino.png"} className="Pc--Casino" />
                    </a>
                    <a href={linkLogin}>
                      <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Group3/PcLotto.png"} className="Pc--Lotto" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="home--MB">
              <div className="Container--HMB">
                <div className="homeMB--section">
                  <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/ImgMobie/ImgHome/Groub1/MBBanner.png"} className="MB--Banner"></img>
                  <div className="img--bg--mb">
                    <Splide
                      className="slide"
                      options={{
                        rewind: false,
                        gap: "10px",
                        type: "loop",
                        autoplay: true,
                        arrows: false,
                      }}
                    >
                      <SplideSlide>
                        <a href={linkLogin}>
                          <img src={"https://fs.cdnxn.com/landingSbo55/Images/promoPC/promoPC1.jpg"} className="w-100" />
                        </a>
                      </SplideSlide>
                      <SplideSlide>
                        <a href={linkLogin}>
                          <img src={"https://fs.cdnxn.com/landingSbo55/Images/promoPC/promoPC2.jpg"} className="w-100" />
                        </a>
                      </SplideSlide>
                      <SplideSlide>
                        <a href={linkLogin}>
                          <img src={"https://fs.cdnxn.com/landingSbo55/Images/promoPC/promoPC3.jpg"} className="w-100" />
                        </a>
                      </SplideSlide>
                      <SplideSlide>
                        <a href={linkLogin}>
                          <img src={"https://fs.cdnxn.com/landingSbo55/Images/promoPC/promoPC4.jpg"} className="w-100" />
                        </a>
                      </SplideSlide>
                    </Splide>
                  </div>
                  <div className="mb--icon-log-regis">
                    <a href={linkLogin}>
                      <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/ImgMobie/ImgHome/Groub1/MBLogin.png"} className="MB--Login"></img>
                    </a>
                    <a href={linkRegister}>
                      <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/ImgMobie/ImgHome/Groub1/MBRegister.png"} className="MB--Register"></img>
                    </a>
                  </div>
                  <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/ImgMobie/ImgHome/Groub2/PcTextGroup.png"} className="MB--TextGroup "></img>
                  <div className="games--groub">
                    <div className="games-groub1">
                      <a href={linkLogin}>
                        <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/ImgMobie/ImgHome/Groub2/MBAllGame.png"} className="MB--AllGame "></img>
                      </a>
                      <a href={linkLogin}>
                        <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/ImgMobie/ImgHome/Groub2/MBCasino.png"} className="MB--Casino "></img>
                      </a>
                      <a href={linkLogin}>
                        <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/ImgMobie/ImgHome/Groub2/MBLotto.png"} className="MB--Lotto "></img>
                      </a>
                    </div>
                    <div className="games-groub2">
                      <a href={linkLogin}>
                        <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/ImgMobie/ImgHome/Groub2/MBSlot.png"} className="MB--Slot "></img>
                      </a>
                      <a href={linkLogin}>
                        <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/ImgMobie/ImgHome/Groub2/MBSport.png"} className="MB--Sport "></img>
                      </a>
                    </div>
                  </div>
                  <div className="games--all">
                    <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/ImgMobie/ImgHome/Groub3/MBTextAll.png"} className="MB--TextAll "></img>
                    <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/ImgMobie/ImgHome/Groub3/MBBgBanner.png"} className="MB--BgBanner "></img>
                    <a href="https://line.me/ti/p/@sbo55.com">
                      <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/ImgMobie/ImgHome/Groub3/MBLine.png"} className="MBLine "></img>
                    </a>
                  </div>
                  <div className="MBimg--games--show">
                    <Carousel responsive={responsive}>
                      <div>
                        <a href={linkLogin}>
                          <img src={"https://fs.cdnxn.com/landingSbo55/Images/Game1.png"} className="games--manu1" />
                        </a>
                      </div>
                      <div>
                        <a href={linkLogin}>
                          <img src={"https://fs.cdnxn.com/landingSbo55/Images/Game2.png"} className="games--manu1" />
                        </a>
                      </div>
                      <div>
                        <a href={linkLogin}>
                          <img src={"https://fs.cdnxn.com/landingSbo55/Images/Game3.png"} className="games--manu1" />
                        </a>
                      </div>
                      <div>
                        <a href={linkLogin}>
                          <img src={"https://fs.cdnxn.com/landingSbo55/Images/Game4.png"} className="games--manu1" />
                        </a>
                      </div>
                      <div>
                        <a href={linkLogin}>
                          <img src={"https://fs.cdnxn.com/landingSbo55/Images/Game5.png"} className="games--manu1" />
                        </a>
                      </div>
                      <div>
                        <a href={linkLogin}>
                          <img src={"https://fs.cdnxn.com/landingSbo55/Images/Game6.png"} className="games--manu1" />
                        </a>
                      </div>
                      <div>
                        <a href={linkLogin}>
                          <img src={"https://fs.cdnxn.com/landingSbo55/Images/Game7.png"} className="games--manu1" />
                        </a>
                      </div>
                    </Carousel>
                    ;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
