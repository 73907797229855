import './Style/Header.css';
import './Style/Home.css';
import RoutePages from './Components/RoutePages';
function App() {
  return (
    <>
    <div className='wrapper wrapper--bg'>
      {/* <Header /> */}
        <RoutePages />
      {/* <Footer /> */}
    </div>

    
    </>
  );
}

export default App;
