import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Style/Header.css";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";



export default function Header() {
  const [linkLogin, setLinkLogin] = useState(
    "https://game.sbobet55.bet"
  );

  const [linkRegister, setLinkRegister] = useState(
    "https://game.sbobet55.bet/register"
  );
  const [show, setShow] = useState(false);
  const [showNofi, setShowNofi] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseNofi = () => setShowNofi(false);
  const handleShowNofi = () => setShowNofi(true);
  return (
    <>
      <div>
        <div className="header">
          <div className="header-section">
            <div className="header--PC">
              <div className="section--bg--left">
                <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Groub1/PcBg.png"} className="Pc--Bg"></img>
                <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Groub1/Header/PcHeader.png"} className="Pc--Header"></img>
                <a href={"/"}>
                  <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Logo.png"} className="PC--logo"></img>
                </a>
                <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Groub1/tabshow.png"} className="tab--show"></img>
                <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/Footer/a11.png"} className="PcFultter"></img>
                <div className="text--class--img">
                  <div className="text--bg">
                    <div className="Button--tx1">
                      <a href={linkLogin}>
                        <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Groub1/tx1.png"} className="tx--1"></img>
                      </a>
                    </div>
                    <div className="Button--tx2">
                      <a href={linkLogin}>
                        <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Groub1/tx2.png"} className="tx--1"></img>
                      </a>
                    </div>
                    <div className="Button--tx3">
                      <a href="Promotion/">
                        <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Groub1/tx3.png"} className="tx--1"></img>
                      </a>
                    </div>
                    <div className="Button--tx4">
                      <a href={linkLogin}>
                        <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Groub1/tx4.png"} className="tx--1"></img>
                      </a>
                    </div>
                    <div className="Button--tx5">
                      <a href={linkLogin}>
                        <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Groub1/tx5.png"} className="tx--1"></img>
                      </a>
                    </div>
                    <div className="Button--tx6">
                      <a href={linkLogin}>
                        <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Groub1/tx6.png"} className="tx--1"></img>
                      </a>
                    </div>
                    <div className="Button--tx7">
                      <a href={linkLogin}>
                        <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Groub1/tx7.png"} className="tx--1"></img>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="text--tab">
                  <marquee scrollamount="6">
                    ยินดีต้อนรับเข้าสู่ SBO55 เว็บออนไลน์อันดับ 1 ในไทย
                    มีทั้งบอล มวย หวย สล๊อต คาสิโน และ อื่นๆอีกมากมาย เว็บตรง
                    มั่นคง ปลอดภัย ฝาก-ถอน อัตโนมัติ
                    แอดมินคอยให้บริการลูกค้าตลอด 24 ชั่วโมง
                  </marquee>
                </div>
                <div className="Button--login--regis">
                  <div className="Button--login">
                    <a href={linkRegister}>
                      <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Groub1/PcRegister.png"} className="Pc--Register"></img>
                    </a>
                  </div>
                  <div className={linkLogin}>
                    <a href={linkLogin}>
                      <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Groub1/PcLogin.png"} className="Pc--Login"></img>
                    </a>
                  </div>
                </div>
                <div className="icon--footter">
                  <a href={linkLogin}>
                    <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/Footer/a1.png"} className="Pc--Wallet--Icon"></img>
                  </a>
                  <a href={linkLogin}>
                    <img
                      src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/Footer/a2.png"}
                      className="Pc--Deposit--Icon"
                    ></img>
                  </a>
                  <a href={linkLogin}>
                    <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/Footer/a3.png"} className="Pc--Text--Icon"></img>
                  </a>
                  <a href={linkLogin}>
                    <img
                      src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/Footer/a4.png"}
                      className="Pc--Withdraw--Icon"
                    ></img>
                  </a>
                  <a href={"Promotion/"}>
                    <img
                      src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/Footer/a5.png"}
                      className="Pc--Promotion--Icon"
                    ></img>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="header--MB">
            <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/Header/MBHeader.png"} className="MB--Header"></img>
            <Container>
              <div className="header--section">
                <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/ImgMobie/MBTabTextShow.png"} className="MB--TabTextShow"></img>
                <div className="text--tab--mb">
                  <marquee scrollamount="6">
                    ยินดีต้อนรับเข้าสู่ SBO55 เว็บออนไลน์อันดับ 1 ในไทย
                    มีทั้งบอล มวย หวย สล๊อต คาสิโน และ อื่นๆอีกมากมาย เว็บตรง
                    มั่นคง ปลอดภัย ฝาก-ถอน อัตโนมัติ
                    แอดมินคอยให้บริการลูกค้าตลอด 24 ชั่วโมง
                  </marquee>
                </div>
                <a href={"/"}>
                  <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/Logo.png"} className="MB--Logo"></img>
                </a>
                <div className="Icon--section">
                  <img
                    src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/Header/MBIconNoti.png"}
                    className="MB--IconNoti"
                    onClick={handleShowNofi}
                  />
                  <img
                    src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/Header/MBIconMenu.png"}
                    className="MB--IconMenu"
                    onClick={handleShow}
                  />
                </div>
              </div>
              <div className="footer--section">
                <a href={linkLogin}>
                  <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/Footer/a1.png"} className="MB--WalletIcon "></img>
                </a>
                <a href={linkLogin}>
                  <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/Footer/a2.png"} className="MB--DepositIcon"></img>
                </a>
                <a href={linkLogin}>
                  <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/Footer/a3.png"} className="MB--TextIcon"></img>
                </a>
                <a href={linkLogin}>
                  <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/Footer/a4.png"} className="MB--WithdrawIcon"></img>
                </a>
                <a href="Promotion/">
                  <img
                    src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/Footer/a5.png"}
                    className="MB--PromotionIconr"
                  ></img>
                </a>
              </div>
            </Container>
            <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImageMB/Footer/a11.png"} className="MB--Footer"></img>
          </div>
        </div>
      </div>

      <Modal
        show={showNofi}
        onHide={handleCloseNofi}
        className="showmenu--nofi--modal"
      >
        <div className="wrapper--menu--nofi">
          <div
            onClick={handleCloseNofi}
            className="showmenu--nofi--modal--close"
          >
            X
          </div>
          <div className="text--noti">
            <h2 className="textnoti">แจ้งเตือน</h2>
          </div>
          <div className="wrapper--section--nofi">
            <div>
              <div className="menu--nofi">
                <Container className="box--nofi">
                  <Row>
                    <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Promotion/PcBgmini.png"} className="bg--tab--blue--nofi" />
                    <Tabs defaultActiveKey="tab-1">
                      <Tab eventKey="tab-1" title="ทั้งหมด">
                        <Row>
                          <Col xs={12} className="games mb-2">
                            คุณไม่มีข้อความ
                          </Col>
                        </Row>
                      </Tab>
                      <Tab eventKey="tab-2" title="ฝากเงิน">
                        <Row>
                          <Col xs={12} className="games mb-2">
                            คุณไม่มีข้อความ
                          </Col>
                        </Row>
                      </Tab>
                      <Tab eventKey="tab-3" title="ถอนเงิน">
                        <Row>
                          <Col xs={12} className="games mb-2">
                            คุณไม่มีข้อความ
                          </Col>
                        </Row>
                      </Tab>
                      <Tab eventKey="tab-4" title="โบนัส">
                        <Row>
                          <Col xs={12} className="games mb-2">
                            คุณไม่มีข้อความ
                          </Col>
                        </Row>
                      </Tab>
                    </Tabs>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal show={show} onHide={handleClose} className="showmenu--modal">
        <div className="wrapper--menu">
          <div className="wrapper--section-">
            <div>
              <div className="menu">
                <div onClick={handleClose} className="showmenu--modal--close">
                  X
                </div>
                <div className="text--bg--menu">
                  <div className="MBButton--tx1">
                    <a href={linkLogin}>
                      <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Groub1/tx1.png"} className="tx--1"></img>
                    </a>
                  </div>
                  <div className="MBButton--tx2">
                    <a href={linkLogin}>
                      <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Groub1/tx2.png"} className="tx--1"></img>
                    </a>
                  </div>
                  <div className="MBButton--tx3">
                    <a href={linkLogin}>
                      <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Groub1/tx3.png"} className="tx--1"></img>
                    </a>
                  </div>
                  <div className="MBButton--tx4">
                    <a href="Promotion/">
                      <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Groub1/tx4.png"} className="tx--1"></img>
                    </a>
                  </div>
                  <div className="MBButton--tx5">
                    <a href={linkLogin}>
                      <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Groub1/tx5.png"} className="tx--1"></img>
                    </a>
                  </div>
                  <div className="MBButton--tx6">
                    <a href={linkLogin}>
                      <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Groub1/tx6.png"} className="tx--1"></img>
                    </a>
                  </div>
                  <div className="MBButton--tx7">
                    <a href={linkLogin}>
                      <img src={"https://fs.cdnxn.com/landingSbo55/Images/ImagePC/Groub1/tx7.png"} className="tx--1"></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
